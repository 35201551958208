import React from 'react';
import Page from './page';

function page1({
  className, sex = 'male', days, totalHours, totalCourses, campus, qrcode,
}) {
  return (
    <Page
      bgImg={`https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/${sex}_share.jpg`}
      text={(
        <>
          <div
            style={{
              position: 'absolute', top: '10%', padding: '5vh 0 0 5vh', width: '100%', textShadow: '1px 1px 2px rgb(0,0,0,0.6)',
            }}
          >
            {/* <div style={{ lineHeight: 2, fontSize: '2.2vh' }}>
              随着登录系统刷新和回车键缓缓落下,
              <br />
              我知道我还是等到了你!
            </div>
            <div style={{ fontSize: '3.8vh', marginTop: '5%' }}>
              相识的
              <b>{days}</b>
              天
              <br />
              {totalCourses > 0 && totalHours > 0 && (
                <span>
                  <b>{totalCourses}</b>
                  门课&nbsp;&nbsp;
                  <b>{totalHours}</b>
                  个小时里
                </span>
              )}
            </div>
            <div style={{ fontSize: '2.5vh' }}>
              我们也都有了日新月异的变化!
              <br/>
              思睿期待与你继续创造精彩!
            </div> */}
            <div
              style={{
                fontSize: '2vh', fontFamily: 'KaiTi', textAlign: 'center', marginLeft: '5%',
              }}
            >
              思润少年留洋路，睿造明日驶方舟。
              <br />
              教伊习得万卷书，育人行就万里路。
              <br />
              爱与科技成明日，瑞雪丰年兆此时。
              <br />
              无惧疫情与距离，思睿教育与你在一起。
            </div>
          </div>
          {qrcode}
        </>
      )}
    ></Page>
  );
}

export default page1;
