import React, { useState, useEffect } from 'react';

function page({
  text, bgImg, children,
}) {
  const [textClass, setTextClass] = useState('hide');
  useEffect(() => {
    setTimeout(() => {
      setTextClass('fadeIn');
    }, [1000]);
  }, []);
  return (
    <>
      <div
        className={textClass}
      >
        {text}
      </div>
      <img crossOrigin="Anonymous" src={bgImg} alt="" style={{ height: '100vh' }}></img>
      <div
        style={{
          width: '100%', position: 'absolute', bottom: '5%', textAlign: 'center',
        }}
      >
        <img
          crossOrigin="Anonymous"
          src="https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/logo.png"
          alt=""
          style={{ width: 130 }}
        ></img>
      </div>
    </>
  );
}

export default page;
