import React from 'react';
import moment from 'moment';
import Page from './page';

function page1({
  className, sex = 'male', firstDate, firstPaidCourse, qrcode,
}) {
  return (
    <Page
      bgImg={`https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/${sex}_3.jpg`}
      text={(
        <>
          <div style={{ position: 'absolute', top: '16%', right: '5%' }}>
            <div style={{ textAlign: 'right', fontSize: 25 }}>
              <b>{moment(firstDate).format('YYYY年MM月DD日')}</b>
              <br />
              我们在
              <b>{firstPaidCourse}</b>
              相遇
            </div>
            <br />
            <div style={{ fontSize: 14, marginLeft: 10 }}>
              两人相遇的几率是0.00487,
              <br />
              这是属于我们故事的开始
            </div>
            <div style={{ fontSize: 28, marginLeft: 50, marginTop: 20 }}>
              感谢有你,
              <div style={{ fontSize: 18 }}>
                我们总共一起度过了
                <b>{moment(moment()).diff(firstDate, 'days')}</b>
                天
              </div>
            </div>
          </div>
          {qrcode}
        </>
      )}
    ></Page>
  );
}

export default page1;
