import React from 'react';
import Page from './page';

function page1({
  className, sex = 'male', wholeTermCourses, totalHours, qrcode,
}) {
  return (
    <Page
      bgImg={`https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/${sex}_4.jpg`}
      text={(
        <>
          <div
            style={{
              position: 'absolute', top: '15%', left: '20%', fontSize: 15,
            }}
          >
            星星能在银河与你相遇吗？
            <br />
            我不知道
          </div>
          <div
            style={{
              textAlign: 'right', position: 'absolute', right: '5%', top: '20%', fontSize: 18,
            }}
          >
            但是我知道
            <div style={{ fontSize: 26 }}>
              在
              <b>{wholeTermCourses}</b>
              个全程班里
              <br />
              <b>{totalHours}</b>
              个小时里
            </div>
            <br />
            我早早地见过了你的身影
          </div>
          {qrcode}
        </>
      )}
    ></Page>
  );
}

export default page1;
