import React, { useState, useEffect } from 'react';
import Page from './page';

function page1({
  className, sex = 'male', onChange, onConfirm, prefix, setPrefix, error, loading, audioRef,
}) {
  return (
    <Page
      bgImg={`https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/${sex}_1.jpg`}
      text={(
        <div style={{ position: 'absolute', top: '15%', width: '100%' }}>
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ marginBottom: '20%' }}>思睿年度学习报告</h2>
            <div style={{ display: 'block' }}>
              <input
                className="input"
                id="phone"
                disabled={loading}
                onChange={onChange}
                placeholder="请输入登录LMS的手机号"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    onConfirm();
                  }
                }}
                style={{ display: 'inline', width: '60%' }}
              ></input>
              <label htmlFor="phone" className="label">LMS登录手机号</label>
            </div>
            {error && !loading && (
              <div
                style={{
                  color: 'white', position: 'relative', top: -10, fontSize: 12,
                }}
              >
                请输入正确的手机号
              </div>
            )}
            <div>
              <button
                type="button"
                disabled={loading}
                onClick={() => {
                  if (!loading) {
                    onConfirm();
                  }
                }}
                style={{ padding: '8px 15px', fontSize: 16, marginTop: '5%' }}
              >
                {loading ? '报告生成中...' : '生成报告'}
              </button>
            </div>
          </div>
        </div>
      )}
    >
    </Page>
  );
}

export default page1;
