import React from 'react';
import Page from './page';

function page1({
  className, sex = 'male', ips, qrcode,
}) {
  return (
    <Page
      bgImg={`https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/${sex}_5.jpg`}
      text={(
        <>
          <div
            style={{
              position: 'absolute', textAlign: 'right', top: '16%', right: '5%',
            }}
          >
            <div style={{ fontSize: 15 }}>与君初相识，犹如故人归</div>
            <div style={{ marginRight: '5%', marginTop: '10%', fontSize: 28 }}>
              你在
              <b>{ips}</b>
              个地方
              <br />
              登录的每一次
            </div>
            <div style={{ fontSize: 19, marginTop: 5, marginRight: 10 }}>
              都是我们相识的里程碑
            </div>
          </div>
          {qrcode}
        </>
      )}
    ></Page>
  );
}

export default page1;
