import React from 'react';
import Page from './page';

function page1({
  className, sex = 'male', studyHour, qrcode,
}) {
  let img = 'night';
  let text = (
    <div
      style={{
        fontSize: 14, position: 'absolute', top: '15%', right: '14%',
      }}
    >
      你经常在
      <b style={{ fontSize: 25 }}>晚上</b>
      8PM-12PM学习
      <div style={{ marginTop: '15%' }}>
        我等过日出阳光散落大地
        <br />
        <span style={{ marginLeft: 30 }}>我等过春夏秋冬四季更替</span>
      </div>
      <div style={{ textAlign: 'right', marginTop: '25%' }}>
        直到晚上,
        <br />
        我才发现,
        <br />
        时光正好，原来你也在这里
      </div>
    </div>
  );
  if (studyHour < 7) {
    text = (
      <div
        style={{
          fontSize: 14, position: 'absolute', top: '15%', left: '38%',
        }}
      >
        你经常在
        <b style={{ fontSize: 25 }}>凌晨</b>
        7AM之前学习
        <div style={{ marginTop: 20, marginLeft: 15 }}>
          你熄灭了电脑屏幕的灯光,
          <br />
          <span style={{ marginLeft: 10 }}>我也悄悄陪你看起了月亮</span>
        </div>
      </div>
    );
  } else if (studyHour < 11) {
    img = 'day';
    text = (
      <div
        style={{
          fontSize: 15, position: 'absolute', top: '18%', right: '15%', textAlign: 'right',
        }}
      >
        你经常在
        <b style={{ fontSize: 25 }}>上午</b>
        7-11AM学习
        <div style={{ marginTop: '10%' }}>
          伴随着清晨第一缕阳光,
          <br />
          <span>我陪你一起追逐梦想</span>
        </div>
      </div>
    );
  } else if (studyHour < 14) {
    img = 'day';
    text = (
      <div>
        <div
          style={{
            fontSize: 14, position: 'absolute', top: '21%', left: '10%',
          }}
        >
          你经常在
          <b style={{ fontSize: 25 }}>中午</b>
          11-14PM学习
        </div>
        <div
          style={{
            position: 'absolute', right: '20%', top: '30%',
          }}
        >
          你睡眼惺忪输入着验证码,
          <br />
          <div style={{ marginTop: '12%' }}>
            我在另一端
            <br />
            偷偷把你的名字记了好久
          </div>
        </div>
      </div>
    );
  } else if (studyHour < 20) {
    img = 'day';
    text = (
      <div
        style={{
          fontSize: 15, position: 'absolute', top: '12%', right: '10%', textAlign: 'right',
        }}
      >
        你经常在
        <b style={{ fontSize: 25 }}>下午</b>
        14PM-8PM学习
        <div style={{ marginTop: '12%' }}>
          你看着那数不尽的知识点
          <br />
          <div style={{ marginTop: '12%' }}>
            而我眼前只有
            <br />
            那个散发着青春活力的少
            {sex === 'male' ? '年' : '女'}
          </div>
        </div>
      </div>
    );
  }

  return (
    <Page
      bgImg={`https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/${sex}_study_${img}.jpg`}
      text={(
        <>
          {text}
          {qrcode}
        </>
      )}
    ></Page>
  );
}

export default page1;
