import React from 'react';
import Page from './page';

function page1({
  className, sex = 'male',
}) {
  return (
    <Page
      bgImg={`https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/${sex}_1.jpg`}
      text={(
        <div>
          <div
            style={{
              fontSize: '5vh', fontFamily: 'YouSheBiaoTiHei', position: 'absolute', top: '22%', left: '15%',
            }}
          >
            你在人生路上闪光
            <br />
            思睿伴你成长
          </div>
        </div>
      )}
    ></Page>
  );
}

export default page1;
