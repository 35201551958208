import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import IcomoonReact from 'icomoon-react';
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';
import ReactAudioPlayer from 'react-audio-player';
import iconSet from '../assets/selection.json';
import '../assets/App.css';

import Login from './login';
import Page1 from './page1';
import Page2 from './page2';
import Page3 from './page3';
import Page4 from './page4';
import Page5 from './page5';
import Page6 from './page6';
import Page7 from './page7';

const erpUrl = 'https://erp.savvyuni.com/api/v1';

const App = () => {
  const [stats, setStats] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(0);
  const [phone, setPhone] = useState('');
  const [paused, setPaused] = useState(true);
  const [downloading, setDownloading] = useState(false);
  const audioRef = useRef(null);

  useEffect(() => function onUnmount() {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  }, []);

  const getStats = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${erpUrl}/students/studentStats`, {
        params: {
          location: window.location.hostname.includes('.cn') ? 'cn' : null,
          phone,
        },
      }, {
        headers: { 'Access-Control-Allow-Origin': '*' },
      });
      if (res.status === 200) {
        setStats(res.data);
        setStage(1);
      }
    } catch {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'ios';
    }

    return 'unknown';
  }

  const statObj = {};
  if (stats) {
    statObj.student = {
      sex: stats.sex,
      campus: stats.campus,
    };
    statObj.firstDate = stats.firstDate;
    statObj.days = moment(moment()).diff(stats.firstDate, 'days');
    statObj.wholeTermCourses = stats.allCount;
    statObj.freeCourses = stats.freeCount;
    statObj.courseSorted = Object.values(stats.allCourses).sort((a, b) => b.allAttendedHours - a.allAttendedHours);
    statObj.totalHours = Object.values(stats.allCourses).reduce((acc, cur) => acc + cur.totalHours, 0);
    statObj.firstPaidCourse = stats.firstPaidCourse && stats.firstPaidCourse.course.courseNo;
    statObj.ips = stats.loginStat.ips;
    const sortedStudyHourOfDay = Object.entries(stats.loginStat.hourOfDay).sort((a, b) => b[1] - a[1]);
    statObj.studyHour = sortedStudyHourOfDay[0][0];
  }

  const qrcode = (
    <div
      style={{
        position: 'absolute', fontSize: '1.5vh', top: '60%', left: '5%', width: 160, textAlign: 'center',
      }}
    >
      扫码回复
      <br />
      <span style={{ fontSize: '2vh' }}>【思睿学习报告】</span>
      <br />
      <br />
      领取新年福利
      <br />
      {statObj.student && statObj.student.campus && (
        <img
          src={`https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/${statObj.student.campus}.jpg`}
          alt=""
          style={{ marginTop: '3%', width: 100 }}
        ></img>
      )}
    </div>
  );
  const qrcodeSmall = (
    <div
      style={{
        position: 'absolute', fontSize: '1.2vh', top: '70%', left: '5%', width: 120, textAlign: 'center',
      }}
    >
      扫码回复
      <br />
      <span style={{ fontSize: '1.6vh' }}>【思睿学习报告】</span>
      <br />
      <br />
      领取新年福利
      <br />
      {statObj.student && statObj.student.campus && (
        <img
          src={`https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/${statObj.student.campus}.jpg`}
          alt=""
          style={{ marginTop: '3%', width: 60 }}
        ></img>
      )}
    </div>
  );
  return (
    <div className="container">
      <div className="blur" style={{ position: 'absolute', width: '100%', height: '100%' }}></div>
      <div
        style={{
          position: 'relative', height: '100vh', overflow: 'hidden',
        }}
      >
        {stage > 0 && (
          <IcomoonReact
            className={paused ? '' : 'music'}
            iconSet={iconSet}
            color="white"
            size={28}
            icon="vynil"
            style={{
              position: 'absolute', right: '47%', top: 10, cursor: 'pointer', zIndex: 2,
            }}
            onClick={() => {
              if (audioRef.current) {
                if (audioRef.current.paused) {
                  audioRef.current.play();
                  document.addEventListener('WeixinJSBridgeReady', () => {
                    audioRef.current.play();
                  }, false);
                } else {
                  audioRef.current.pause();
                }
              }
            }}
          />
        )}
        {stage > 1 && (
          <IcomoonReact
            className="left-arrow"
            iconSet={iconSet}
            color="white"
            size={30}
            icon="arrow-left2"
            style={{
              position: 'absolute', top: '50%', left: 10, cursor: 'pointer', zIndex: 2,
            }}
            onClick={() => {
              if (stage > 1) {
                if (stage === 5 && (statObj.wholeTermCourses === 0 || statObj.totalHours === 0)) {
                  setStage(3);
                } else {
                  setStage(stage - 1);
                }
              }
            }}
          />
        )}
        {stage > 0 && stage < 7 && (
          <IcomoonReact
            className="right-arrow"
            iconSet={iconSet}
            color="white"
            size={30}
            icon="arrow-right2"
            style={{
              position: 'absolute', top: '50%', right: 10, cursor: 'pointer', zIndex: 2,
            }}
            onClick={() => {
              if (stage < 7) {
                if (stage === 3 && (statObj.wholeTermCourses === 0 || statObj.totalHours === 0)) {
                  setStage(5);
                } else {
                  setStage(stage + 1);
                }
              }
            }}
          />
        )}
        {/* {stage > 0 && !downloading && (
          <IcomoonReact
            iconSet={iconSet}
            color="white"
            size={30}
            icon="download"
            style={{
              position: 'absolute', right: 10, top: 10, cursor: 'pointer', zIndex: 2,
            }}
            onClick={() => {
              setDownloading(true);
              if (getMobileOperatingSystem() === 'ios') {
                html2canvas(document.querySelector('#report'), { useCORS: true, allowTaint: true }).then((canvas) => {
                  const link = document.createElement('a');
                  link.download = `思睿年度学习报告第${stage}页`;
                  link.href = canvas.toDataURL('image/png');
                  link.click();
                  setDownloading(false);
                });
              } else {
                domtoimage.toJpeg(document.querySelector('#report'), { quality: 1 })
                  .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = `思睿年度学习报告第${stage}页`;
                    link.href = dataUrl;
                    link.click();
                    setDownloading(false);
                  });
              }
            }}
          />
        )}
        {downloading && (
          <div
            className="lds-ring"
            style={{
              position: 'absolute', right: 10, top: 10, cursor: 'pointer', zIndex: 2,
            }}
          >
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        )} */}
        {stage === 0 && (
          <Login
            audioRef={audioRef}
            loading={loading}
            error={error}
            onConfirm={() => {
              if (phone) {
                getStats();
                setTimeout(() => {
                  if (audioRef.current) {
                    console.log(audioRef);
                    audioRef.current.play();
                    document.addEventListener('WeixinJSBridgeReady', () => {
                      audioRef.current.play();
                    }, false);
                  }
                }, [1000]);
              }
            }}
            onChange={e => setPhone(e.target.value)}
            style={{ zIndex: 2 }}
          ></Login>
        )}
        <div
          id="report"
          style={{ position: 'relative', height: '100vh', overflow: 'hidden' }}
        >
          {stage === 1 && (
            <Page1 sex={statObj.student && statObj.student.sex}></Page1>
          )}
          {stage === 2 && <Page2 sex={statObj.student && statObj.student.sex}></Page2>}
          {stage === 3 && <Page3 sex={statObj.student && statObj.student.sex} firstDate={statObj.firstDate} firstPaidCourse={statObj.firstPaidCourse} qrcode={qrcodeSmall}></Page3>}
          {stage === 4 && <Page4 sex={statObj.student && statObj.student.sex} wholeTermCourses={statObj.wholeTermCourses} totalHours={statObj.totalHours} qrcode={qrcodeSmall}></Page4>}
          {stage === 5 && <Page5 sex={statObj.student && statObj.student.sex} ips={statObj.ips} qrcode={qrcodeSmall}></Page5>}
          {stage === 6 && <Page6 sex={statObj.student && statObj.student.sex} studyHour={statObj.studyHour} qrcode={qrcodeSmall}></Page6>}
          {stage === 7 && (
            <Page7
              sex={statObj.student && statObj.student.sex}
              days={statObj.days}
              totalHours={statObj.totalHours}
              totalCourses={statObj.wholeTermCourses}
              qrcode={qrcode}
            ></Page7>
          )}
        </div>
      </div>
      <audio
        ref={audioRef}
        data-html2canvas-ignore
        src="https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/bgmusic.mp3"
        crossOrigin="anonymous"
        autoPlay
        loop
        volume={0.6}
        onPlay={() => {
          setPaused(false);
        }}
        onPause={() => {
          setPaused(true);
        }}
      >
      </audio>
    </div>
  );
};

export default App;
