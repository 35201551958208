import React from 'react';
import Page from './page';

function page1({
  className, sex = 'male',
}) {
  return (
    <Page
      bgImg={`https://savvyuni-public.oss-accelerate.aliyuncs.com/yearly-report/${sex}_2.jpg`}
      text={(
        <div
          style={{
            fontSize: '4vh', position: 'absolute', top: '20%', lineHeight: 2, width: '100%', padding: 'auto', textAlign: 'center',
          }}
        >
          可能我们终究未曾谋面,
          <br />
          <div style={{ marginLeft: 10 }}>对你的关心却一分未减</div>
        </div>
      )}
    ></Page>
  );
}

export default page1;
